<template>
  <v-overlay value="true" class="text-center">
    <v-progress-circular
        indeterminate
        size="64"
    ></v-progress-circular>
    <br>
    <br>
    <br>
    <p class="title">{{ currentAction }} ...</p>
  </v-overlay>
</template>

<script>
export default {
  name: "Logout",
  data() {
    return {
      currentAction: this.$t("home.login.logingOut")
    }
  }
}
</script>

<style scoped>

</style>